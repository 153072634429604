.home-banner {
    height: 100vh;
}
.lift-up-2 {
    position: relative;
    z-index: 2;
    display: inline-block;
}
.portfolioPic,
.home-left-div {
    width: 85%;
}
.home-left-div {
    margin: 50px auto;
}
.folio-primary-button {
    padding: 15px 30px;
    display: inline-block;
    color: white;
    text-decoration: none;
    background: var(--bg-gradient-primary);
    outline: none;
    border: none;
}
.folio-primary-button:hover {
    color: white;
    transform: scale(1.1);
    transition: all 1s ease;
}

/* small mobile */
@media only screen and (max-width: 320px) {
    .home-left-div {
        margin-bottom: 50px;
    }
    .home-left-div h1 {
        font-size: 30px;
    }
    .home-left-div h2 {
        font-size: 20px;
    }
    .home-left-div h4 {
        font-size: 16px;
    }
}

/* medium mobile */
@media only screen and (min-width: 321px) and (max-width: 380px) {
    .home-left-div {
        margin-bottom: 70px;
    }
    .home-left-div h1 {
        font-size: 40px;
    }
    .home-left-div h2 {
        font-size: 30px;
    }
    .home-left-div h4 {
        font-size: 20px;
    }
}

/* large mobile */
@media only screen and (min-width: 381px) and (max-width: 480px) {
    .home-left-div {
        margin-bottom: 80px;
    }
    .home-left-div h1 {
        font-size: 40px;
    }
    .home-left-div h2 {
        font-size: 30px;
    }
    .home-left-div h4 {
        font-size: 20px;
    }
}

/* tablet */
@media only screen and (min-width: 481px) and (max-width: 992px) {
    .home-left-div h1 {
        font-size: 60px;
    }
    .home-left-div h2 {
        font-size: 40px;
    }
    .home-left-div h4 {
        font-size: 25px;
    }
}

@media only screen and (max-width: 992px) {
    .home-left-div {
        margin: 150px auto;
    }
}

/* laptop */
@media (max-width: 1199px) {
    .home-banner {
        height: 100%;
    }
    .portfolioPic {
        width: 95%;
    }
    .home-left-div {
        width: 100%;
    }
}
