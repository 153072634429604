@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);
body {
    margin: 0;
    font-family: "Poppins", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #000;
    color: #fff;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

a {
    color: #149ddd;
}
a:hover {
    color: #1183b9;
}

:root {
    --bg-gradient-primary: linear-gradient(120deg, #d921ce, #1253c5);
    --primary: #149ddd;
}
.main {
    margin-left: 250px;
}
.text-blue {
    color: #149ddd;
    color: var(--primary);
}
.text-justify {
    text-align: justify;
}

/* custom scroll bar */
::-webkit-scrollbar {
    width: 10px;
}
::-webkit-scrollbar-track {
    background: white;
    border-radius: 15px;
    border: 2px solid #000;
}
::-webkit-scrollbar-thumb {
    background: linear-gradient(120deg, #d921ce, #1253c5);
    background: var(--bg-gradient-primary);
    height: 70%;
    border-radius: 15px;
}

@media (max-width: 1199px) {
    .main {
        margin-left: 0;
    }
}

#footer {
    padding: 15px;
    color: #f4f6fd;
    font-size: 14px;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 250px;
    z-index: 501;
    background: #0e0d0d;
}

#footer .copyright {
    text-align: center;
}

#footer .credits {
    padding-top: 5px;
    text-align: center;
    font-size: 13px;
    color: #eaebf0;
}

@media (max-width: 1199px) {
    #footer {
        position: relative;
        width: 100%;
        z-index: 2;
        padding-right: 20px 15px;
    }
}

#header {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 250px;
    transition: all ease-in-out 0.5s;
    z-index: 500;
    transition: all 0.5s;
    padding: 0 15px;
    background: #231f20;
    overflow-y: auto;
}

#header .profile img {
    margin: 15px auto;
    display: block;
    width: 100px;
}

#header .profile h1 {
    font-size: 22px;
    margin: 0;
    padding: 0;
    font-weight: 600;
    text-align-last: center;
    font-family: "Poppins", sans-serif;
    text-align: center;
}

#header .profile h1 a,
#header .profile h1 a:hover {
    color: #fff;
    text-decoration: none;
}

#header .profile .social-links a {
    font-size: 18px;
    display: inline-block;
    background: #212431;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
}

#header .profile .social-links a:hover {
    background: var(--bg-gradient-primary);
    color: #fff;
    text-decoration: none;
}

@media (max-width: 1199px) {
    #header {
        left: -250px;
    }
    #main {
        margin-left: 0;
    }
}

/*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
/* Desktop Navigation */
.nav-menu {
    padding-top: 15px;
}

.nav-menu * {
    margin: 0;
    padding: 0;
    list-style: none;
}

.nav-menu > ul > li {
    position: relative;
    white-space: nowrap;
}

.nav-menu a {
    display: grid;
    grid-template-columns: 1fr 3.5fr;
    align-items: center;
    color: #a8a9b4;
    padding: 12px 15px;
    margin-bottom: 8px;
    transition: 0.3s;
    font-size: 15px;
    text-decoration: none;
}

.nav-menu a i {
    font-size: 20px;
    margin: auto;
    color: #6f7180;
}

.nav-menu a:hover,
.nav-menu .active,
.nav-menu li:hover > a {
    text-decoration: none;
    color: #fff;
}

.nav-menu a:hover i,
.nav-menu .active > i,
.nav-menu li:hover > a i {
    color: #149ddd;
}

/* Mobile Navigation */
.mobile-nav-toggle {
    position: fixed;
    right: 15px;
    top: 15px;
    z-index: 500;
    border: 0;
    background: none;
    font-size: 24px;
    transition: all 0.4s;
    outline: none !important;
    line-height: 1;
    cursor: pointer;
    text-align: right;
}

.mobile-nav-toggle i {
    color: #fff;
}

.mobile-nav-active {
    overflow: hidden;
}

.mobile-nav-active #header {
    left: 0;
}

.mobile-nav-active .mobile-nav-toggle i {
    color: #fff;
}

.home-banner {
    height: 100vh;
}
.lift-up-2 {
    position: relative;
    z-index: 2;
    display: inline-block;
}
.portfolioPic,
.home-left-div {
    width: 85%;
}
.home-left-div {
    margin: 50px auto;
}
.folio-primary-button {
    padding: 15px 30px;
    display: inline-block;
    color: white;
    text-decoration: none;
    background: var(--bg-gradient-primary);
    outline: none;
    border: none;
}
.folio-primary-button:hover {
    color: white;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    transition: all 1s ease;
}

/* small mobile */
@media only screen and (max-width: 320px) {
    .home-left-div {
        margin-bottom: 50px;
    }
    .home-left-div h1 {
        font-size: 30px;
    }
    .home-left-div h2 {
        font-size: 20px;
    }
    .home-left-div h4 {
        font-size: 16px;
    }
}

/* medium mobile */
@media only screen and (min-width: 321px) and (max-width: 380px) {
    .home-left-div {
        margin-bottom: 70px;
    }
    .home-left-div h1 {
        font-size: 40px;
    }
    .home-left-div h2 {
        font-size: 30px;
    }
    .home-left-div h4 {
        font-size: 20px;
    }
}

/* large mobile */
@media only screen and (min-width: 381px) and (max-width: 480px) {
    .home-left-div {
        margin-bottom: 80px;
    }
    .home-left-div h1 {
        font-size: 40px;
    }
    .home-left-div h2 {
        font-size: 30px;
    }
    .home-left-div h4 {
        font-size: 20px;
    }
}

/* tablet */
@media only screen and (min-width: 481px) and (max-width: 992px) {
    .home-left-div h1 {
        font-size: 60px;
    }
    .home-left-div h2 {
        font-size: 40px;
    }
    .home-left-div h4 {
        font-size: 25px;
    }
}

@media only screen and (max-width: 992px) {
    .home-left-div {
        margin: 150px auto;
    }
}

/* laptop */
@media (max-width: 1199px) {
    .home-banner {
        height: 100%;
    }
    .portfolioPic {
        width: 95%;
    }
    .home-left-div {
        width: 100%;
    }
}

.project-img {
    height: 300px;
    object-fit: cover;
}

