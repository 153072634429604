#header {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 250px;
    transition: all ease-in-out 0.5s;
    z-index: 500;
    transition: all 0.5s;
    padding: 0 15px;
    background: #231f20;
    overflow-y: auto;
}

#header .profile img {
    margin: 15px auto;
    display: block;
    width: 100px;
}

#header .profile h1 {
    font-size: 22px;
    margin: 0;
    padding: 0;
    font-weight: 600;
    -moz-text-align-last: center;
    text-align-last: center;
    font-family: "Poppins", sans-serif;
    text-align: center;
}

#header .profile h1 a,
#header .profile h1 a:hover {
    color: #fff;
    text-decoration: none;
}

#header .profile .social-links a {
    font-size: 18px;
    display: inline-block;
    background: #212431;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
}

#header .profile .social-links a:hover {
    background: var(--bg-gradient-primary);
    color: #fff;
    text-decoration: none;
}

@media (max-width: 1199px) {
    #header {
        left: -250px;
    }
    #main {
        margin-left: 0;
    }
}

/*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
/* Desktop Navigation */
.nav-menu {
    padding-top: 15px;
}

.nav-menu * {
    margin: 0;
    padding: 0;
    list-style: none;
}

.nav-menu > ul > li {
    position: relative;
    white-space: nowrap;
}

.nav-menu a {
    display: grid;
    grid-template-columns: 1fr 3.5fr;
    align-items: center;
    color: #a8a9b4;
    padding: 12px 15px;
    margin-bottom: 8px;
    transition: 0.3s;
    font-size: 15px;
    text-decoration: none;
}

.nav-menu a i {
    font-size: 20px;
    margin: auto;
    color: #6f7180;
}

.nav-menu a:hover,
.nav-menu .active,
.nav-menu li:hover > a {
    text-decoration: none;
    color: #fff;
}

.nav-menu a:hover i,
.nav-menu .active > i,
.nav-menu li:hover > a i {
    color: #149ddd;
}

/* Mobile Navigation */
.mobile-nav-toggle {
    position: fixed;
    right: 15px;
    top: 15px;
    z-index: 500;
    border: 0;
    background: none;
    font-size: 24px;
    transition: all 0.4s;
    outline: none !important;
    line-height: 1;
    cursor: pointer;
    text-align: right;
}

.mobile-nav-toggle i {
    color: #fff;
}

.mobile-nav-active {
    overflow: hidden;
}

.mobile-nav-active #header {
    left: 0;
}

.mobile-nav-active .mobile-nav-toggle i {
    color: #fff;
}
