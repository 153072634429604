:root {
    --bg-gradient-primary: linear-gradient(120deg, #d921ce, #1253c5);
    --primary: #149ddd;
}
.main {
    margin-left: 250px;
}
.text-blue {
    color: var(--primary);
}
.text-justify {
    text-align: justify;
}

/* custom scroll bar */
::-webkit-scrollbar {
    width: 10px;
}
::-webkit-scrollbar-track {
    background: white;
    border-radius: 15px;
    border: 2px solid #000;
}
::-webkit-scrollbar-thumb {
    background: var(--bg-gradient-primary);
    height: 70%;
    border-radius: 15px;
}

@media (max-width: 1199px) {
    .main {
        margin-left: 0;
    }
}
