#footer {
    padding: 15px;
    color: #f4f6fd;
    font-size: 14px;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 250px;
    z-index: 501;
    background: #0e0d0d;
}

#footer .copyright {
    text-align: center;
}

#footer .credits {
    padding-top: 5px;
    text-align: center;
    font-size: 13px;
    color: #eaebf0;
}

@media (max-width: 1199px) {
    #footer {
        position: relative;
        width: 100%;
        z-index: 2;
        padding-right: 20px 15px;
    }
}
